import Typed from "typed.js";
const typedOptions = {
  stringsElement: ".typed-strings",
  typeSpeed: 40,
  backSpeed: 0,
  fadeOut: true,
  onComplete: function(self) {
    setTimeout(function() {
      self.reset();
    }, 2000);
  }
};
var typed = new Typed("#typed", typedOptions);
